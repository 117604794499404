<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <div v-if="users.length">
      <vs-table ref="table" search :data="users">
        <template slot="thead">
          <vs-th>الصورة</vs-th>
          <vs-th sort-key="name">الإسم</vs-th>
          <vs-th sort-key="phone">رقم الهاتف</vs-th>
          <vs-th>عمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td
                class="table-image-container flex justify-center items-center"
              >
                <img :src="tr.image" class="table-image" />
              </vs-td>
              <vs-td>
                <p class="user-name font-medium truncate">{{ tr.name }}</p>
              </vs-td>

              <vs-td>
                <p class="userphone">{{ tr.phone }}</p>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="RepeatIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  title="استعادة"
                  @click.stop="openConfirmRetrieve(tr.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="currentPage"
        @change="getData(currentPage)"
      ></vs-pagination>
    </div>
    <EmptyState page="المستخدمين مؤرشفين" :display="false" v-else></EmptyState>
  </div>
</template>

<script>
import EmptyState from "@/views/emptyState.vue";
import { mapActions } from "vuex";
export default {
  components: {
    EmptyState,
  },
  data() {
    return {
      search: "",
      itemsPerPage: 10,
      selected_id: "",
      currentPage: 1,

    };
  },
  computed: {
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.users.length;
    },
    users() {
      return this.$store.state.users.archived;
    },
    lastPage() {
      return this.$store.state.orders.pagination;
    },
  },

  methods: {
    ...mapActions("users", ["fetchArchivedUsers", "retrieveUser"]),
    openConfirmRetrieve(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: ` هل انت متأكد من استعادة هذا المستخدم؟  `,
        accept: this.retrieveData,
        acceptText: "نعم",
        cancelText: "الغاء",
      });
    },
    retrieveData() {
      this.retrieveUser(this.selected_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  created() {
    this.fetchArchivedUsers();
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
